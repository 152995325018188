<script setup>
import BaseCard from "@/components/BaseCard.vue";

import { ATTEMPT_BY_GENERAL_PROCESAL, DIFFICULTY_OPTIONS, QUESTION_SUBTYPE_OPTIONS } from "@/components/utils.js";
import { computed, onMounted, ref } from "vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseRange from "@/components/forms/BaseRange.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import AttemptSelectorHeader from "@/components/attempt-selector/AttemptSelectorHeader.vue";
import ContentLevelSelector from "@/components/attempt-selector/ContentLevelSelector.vue";
import RadioButton from "@/components/forms/RadioButton.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import MainRepository from "@/repositories/MainRepository.js";
import { useToast } from "@/components/composables/notifications.js";

const { sendServerError } = useToast();

const { items: laws, getList: getLaws, isLoading: loadingLaws } = usePagination(MainRepository.contentLawList, 100, 0);
const {
  items: contentLevels,
  getList: getContentLevels,
  isLoading: loadingContents,
} = usePagination(MainRepository.contentContentLevelList, 100, 0);

const props = defineProps({
  publicExamination: {
    type: Object,
    required: true,
  },
  selectedType: {
    type: String,
    required: true,
  },
  selectedDifficulty: {
    type: [String, null],
    required: true,
  },
  selectedSubtype: {
    type: [String, null],
    required: true,
  },
  questions: {
    type: Number,
    required: true,
  },
});

const nQuestions = ref(props.questions);
const isLawSelected = ref(false);
const contentSelector = ref(null);
const lawSelector = ref(null);

const minQuestions = computed(() => 10);
const maxQuestions = computed(() => 100);

const contentLevelOptions = computed(() => [
  {
    label: "Por tema",
    value: false,
  },
  {
    label: "Por ley",
    value: true,
  },
]);

const isLoading = computed(() => {
  return (isLawSelected.value && loadingLaws.value) || (!isLawSelected.value && loadingContents.value);
});
const computedContentLevels = computed(() => {
  let output = {};
  if (isLawSelected.value) {
    output["type"] = "laws";
    output["values"] = lawSelector.value.selectedLevels;
  } else if (showSelectTestContent.value) {
    output["type"] = "contentLevels";
    output["values"] = contentSelector.value.selectedLevels;
  } else if (props.publicExamination.slug === "ofimatica-tramitacion-procesal") {
    output["type"] = "contentLevels";
    output["values"] = contentSelector.value.selectedLevels;
  }
  return output;
});

const showSelectType = computed(() => {
  return props.selectedType === ATTEMPT_BY_GENERAL_PROCESAL;
});
const showSelectTestContent = computed(() => {
  return (
    props.selectedType !== ATTEMPT_BY_GENERAL_PROCESAL &&
    props.publicExamination.slug !== "ofimatica-tramitacion-procesal"
  );
});

const emit = defineEmits([
  "unselect-public-examination",
  "unselect-type",
  "select-difficulty",
  "select-subtype",
  "update-questions",
  "move",
]);

onMounted(async () => {
  try {
    await getContentLevels({
      public_examination__slug: props.publicExamination.slug,
    });
    await getLaws({});
  } catch (error) {
    sendServerError(error);
  }
});

function unselectPublicExamination() {
  emit("unselect-public-examination");
}

function unselectType() {
  emit("unselect-type");
}

function selectDifficulty(difficulty) {
  emit("select-difficulty", difficulty);
}

function selectSubtype(difficulty) {
  emit("select-subtype", difficulty);
}

function updateQuestions(value) {
  let result = parseInt(value);
  if (result < minQuestions.value) {
    result = minQuestions.value;
  }
  if (result > maxQuestions.value) {
    result = maxQuestions.value;
  }
  nQuestions.value = result;
  emit("update-questions", result);
}

function updateQuestionInput(event) {
  updateQuestions(event.target.value);
}

function updateSingleStep(increase) {
  if (increase) {
    updateQuestions(props.questions + 1);
  } else {
    updateQuestions(props.questions - 1);
  }
}

function move() {
  emit("move", computedContentLevels.value);
}
</script>

<template>
  <div>
    <attempt-selector-header
      :public-examination="publicExamination"
      :selected-exam="null"
      :selected-type="selectedType"
      :selected-difficulty="null"
      :questions="null"
      :selected-contents="null"
      @unselect-public-examination="unselectPublicExamination"
      @unselect-type="unselectType"
    />
    <base-card class="!mx-0 mt-16 w-full border-0 !p-0 drop-shadow-none" v-if="showSelectType">
      <h2 class="mb-7 ml-5 mt-3 text-3xl font-normal">Elige el tipo de pregunta</h2>
      <div class="cuantas-preguntas-card tipo-pregunta custom-shadow rounded-2xl">
        <div class="flex w-fit rounded-xl bg-[#FEF4E5] p-2">
          <div
            v-for="(subtype, index) in QUESTION_SUBTYPE_OPTIONS"
            :key="index"
            class="tipo-pregunta-pill flex cursor-pointer items-center rounded-lg px-4 py-2 text-lg leading-none"
            :class="selectedSubtype === subtype.value ? 'bg-[#F39200] text-white' : ''"
            @click.prevent="selectSubtype(subtype.value)"
          >
            <span>{{ subtype.label }}</span>
          </div>
        </div>
      </div>
    </base-card>
    <base-card class="mt-4" v-if="false">
      <h3 class="mb-7 ml-5 mt-3 text-xl">Elige la dificultad</h3>
      <div class="grid grid-cols-3">
        <div
          v-for="(difficulty, index) in DIFFICULTY_OPTIONS"
          :key="index"
          class="hover:bg-background-hover' m-4 flex cursor-pointer flex-col items-center rounded rounded-lg bg-background px-5 py-6"
          :class="selectedDifficulty === difficulty.value ? 'bg-primary-300' : ''"
          @click.prevent="selectDifficulty(difficulty.value)"
        >
          <span class="mb-2 text-xl">{{ difficulty.label }}</span>
          <span class="text-sm">{{ difficulty.description }}</span>
        </div>
      </div>
    </base-card>
    <base-card class="!mx-0 mt-16 w-fit border-0 !p-0 drop-shadow-none">
      <h2 class="mb-7 ml-5 mt-3 text-2xl font-normal md:text-3xl">¿De cuántas preguntas quieres hacer el test?</h2>
      <div class="cuantas-preguntas-card custom-shadow rounded-2xl">
        <div class="flex flex-col items-start gap-4 md:flex-row">
          <base-icon icon-name="npreguntas" />
          <div>
            <h3 class="mb-2 text-xl font-semibold">Número de preguntas</h3>
            <p class="text-base font-normal">Elige la cantidad de preguntas que quieres que tenga el test</p>
            <div class="mt-1 flex flex-col items-start">
              <div class="flex text-5xl">
                <button class="mr-4 cursor-pointer font-light" @click.prevent="updateSingleStep(false)">-</button>
                <input
                  type="number"
                  :min="minQuestions"
                  :max="maxQuestions"
                  v-model.number="nQuestions"
                  class="cuantas-preguntas-number-input mr-4 w-[10rem] border-b border-b-secondary-190 text-center text-6xl font-normal"
                  @change="updateQuestionInput"
                />
                <button class="cursor-pointer font-light" @click.prevent="updateSingleStep(true)">+</button>
              </div>
              <div class="mt-4 flex w-[15.5rem] flex-row">
                <base-range
                  :min="minQuestions"
                  :max="maxQuestions"
                  step="1"
                  :model-value="questions"
                  @update:modelValue="updateQuestions"
                  class="cuantas-preguntas-range w-full align-middle"
                  id="range-n-questions"
                  name="range-n-questions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-card>
    <base-card v-show="showSelectTestContent" class="!mx-0 mt-16 border-0 !p-0 drop-shadow-none">
      <h2 class="mb-7 ml-5 mt-3 text-2xl font-normal md:text-3xl">Contenido del test</h2>
      <div class="cuantas-preguntas-card custom-shadow rounded-2xl">
        <div class="mb-4 flex flex-col items-start gap-4 md:flex-row">
          <base-icon icon-name="contenidos" />
          <div>
            <h3 class="mb-2 text-xl font-semibold">Contenido del test</h3>
            <p class="text-base font-normal">Selecciona el contenido que quieras que aparezca en el test</p>
          </div>
        </div>
        <div class="m-5 ml-0 flex flex-col">
          <div class="mb-5 flex items-center justify-between">
            <radio-button
              v-model="isLawSelected"
              :options="contentLevelOptions"
              id="radio-law-content-level"
              class="por-ley-o-tema-radio"
            />
            <button-primary
              class="hidden items-center gap-4 !rounded-[1rem] !px-7 !py-3 text-white hover:!bg-[#F6B34D] md:flex"
              @click="move"
            >
              Continuar <base-icon icon-name="arrowcirclewhite" />
            </button-primary>
          </div>
          <div v-if="isLawSelected" class="mb-3">
            <content-level-selector
              ref="lawSelector"
              name="law-selector"
              :content-levels="laws"
              :public-examination="publicExamination"
              :title-selector="(element) => element.shortName"
              :show-description="true"
              :description-selector="(element) => element.name"
              :is-loading="isLoading"
            />
          </div>
          <div v-else>
            <content-level-selector
              ref="contentSelector"
              name="content-level-selector"
              :content-levels="contentLevels"
              :public-examination="publicExamination"
              :title-selector="(element) => element.name"
              :is-loading="isLoading"
            />
          </div>
        </div>
      </div>
    </base-card>
    <div class="mt-8 flex md:hidden">
      <button-primary
        class="flex items-center gap-4 !rounded-[1rem] !px-7 !py-3 text-white hover:!bg-[#F6B34D]"
        @click="move"
      >
        Continuar <base-icon icon-name="arrowcirclewhite" />
      </button-primary>
    </div>
  </div>
</template>
