<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  Bars3Icon,
  ChevronDownIcon,
  HandRaisedIcon,
  UserCircleIcon,
  XMarkIcon,
  BellIcon,
} from "@heroicons/vue/24/outline";
import { computed, inject, onBeforeUnmount, onMounted, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import MainRepository from "@/repositories/MainRepository.js";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import { useVersioning } from "@/components/composables/versioning.js";
import { useToast } from "@/components/composables/notifications.js";

defineProps({
  publicExaminations: {
    type: Array,
    default: () => [],
  },
});
const user = inject("user");
const route = useRoute();
const { sendServerError } = useToast();
const { isQA } = useVersioning();

const fullName = computed(() => {
  if (user.value == null) return "";
  return user.value.firstName + " " + user.value.lastName;
});

const doTestLink = computed(() => {
  return { name: "Hacer test", href: { name: "home" }, current: route.name === "home" };
});

const navigation = computed(() => {
  const list = [
    {
      name: "Oposiciones",
      href: { name: "home" },
      current: route.name === "home",
    },
    {
      name: "Estadísticas",
      href: { name: "public-examination-user-statistics-no-slug" },
      current: route.name === "public-examination-user-statistics-no-slug",
    },
  ];
  if (user.value != null && (user.value.isSuperuser || user.value.isStaff || user.value.isAcademyStaff)) {
    list.push({
      name: "Usuarios",
      href: {
        name: "manage-users",
      },
    });
    list.push({
      name: "Estadísticas generales",
      href: {
        name: "staff-public-examination-statistics",
      },
    });
  }
  return list;
});

const mobileNavigation = computed(() => {
  const list = [...navigation.value];
  list.push(doTestLink.value);
  return list;
});

const closeSession = async () => {
  await MainRepository.logout();
  window.location = import.meta.env.VITE_BACKEND_URL;
};

const hasNotification = ref(false);
const fetchNotifications = async () => {
  try {
    const response = await MainRepository.getPendingNotifications();
    hasNotification.value = response.data.hasPendingNotifications;
  } catch (e) {
    sendServerError(e, "ALERT-NOTIFY");
  }
};

let notificationInterval;
onMounted(() => {
  fetchNotifications();
  notificationInterval = setInterval(fetchNotifications, 300000); // 5min
});

onBeforeUnmount(() => {
  clearInterval(notificationInterval);
});

const adminUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin";

const isSecondaryMenuActive = ref(false);
const toggleSecondaryMenu = () => {
  isSecondaryMenuActive.value = !isSecondaryMenuActive.value;
  const menu = document.querySelector(".test-new-menu-secondary-container");
  if (menu) {
    menu.classList.toggle("active", isSecondaryMenuActive.value);
  }
};

const openSection = ref(null);
const toggleSection = async (index) => {
  const sections = document.querySelectorAll(".secondary-menu");

  if (openSection.value === index) {
    const currentSection = sections[index];
    const content = currentSection.querySelector(".secondary-menu-list");
    if (content) {
      content.style.maxHeight = "0px";
    }
    openSection.value = null;
  } else {
    openSection.value = index;
    await nextTick();

    sections.forEach((section, i) => {
      const content = section.querySelector(".secondary-menu-list");
      if (content) {
        if (i === index) {
          content.style.maxHeight = `${content.scrollHeight}px`;
        } else {
          content.style.maxHeight = "0px";
        }
      }
    });
  }
};
</script>

<template>
  <Disclosure
    as="nav"
    class="main-nav sticky top-0 z-20 h-screen bg-[#F7F8FF] p-4 pr-0"
    :class="{ '!bg-[#F9E0E0]': isQA }"
  >
    <div class="main-new-menu-mobile flex items-center justify-between px-4 py-2 md:hidden">
      <a class="flex gap-5" href="https://ruthgalvan.es" target="_blank">
        <img class="h-8" src="@/assets/logo-ruth-galvan-icono.svg" alt="Academia Ruth Galván logo" />
        <div v-if="isQA" class="text-center text-3xl font-bold">QA</div>
      </a>
      <button class="menu-icon rounded-xl p-3" @click="toggleSecondaryMenu">
        <base-icon class="w-full" :icon-name="isSecondaryMenuActive ? 'closemenumobile' : 'openmenumobile'" />
      </button>
    </div>
    <div class="flex h-full">
      <div
        class="main-nav-icons-menu-container relative z-[16] mr-4 flex h-full shrink-0 flex-col rounded-xl bg-white px-3 py-8"
      >
        <div class="main-nav-logo-container mb-5 hidden flex-shrink-0 md:block">
          <p v-if="isQA" class="mb-5 text-center text-3xl font-bold">QA</p>
          <a href="https://ruthgalvan.es" target="_blank">
            <img class="h-7" src="@/assets/logo-ruth-galvan-icono.svg" alt="Academia Ruth Galván logo" />
          </a>
        </div>
        <div class="menu-icons flex flex-col">
          <button
            class="menu-icon menu-icon-open-menu mb-5 hidden rounded-xl p-3 md:block lg:hidden"
            @click="toggleSecondaryMenu"
          >
            <base-icon class="w-full" icon-name="togglemenutablet" />
          </button>
          <router-link to="/" class="menu-icon rounded-xl p-3" :class="{ 'bg-[#EEF0FC]': route.name === 'home' }">
            <base-icon class="w-full" icon-name="menuhome" />
          </router-link>
          <router-link
            to="/notificaciones"
            class="menu-icon rounded-xl p-3"
            :class="{ 'bg-[#EEF0FC]': route.name === 'notification-list' }"
          >
            <base-icon class="w-full" icon-name="menunotifications" />
          </router-link>
          <router-link
            to="/preguntas"
            class="menu-icon rounded-xl p-3"
            :class="{ 'bg-[#EEF0FC]': route.name === 'questions-list' }"
          >
            <base-icon class="w-full" icon-name="menuquestions" />
          </router-link>
        </div>
        <div class="menu-icons flex flex-grow flex-col justify-end">
          <router-link
            to="/configuracion"
            class="menu-icon rounded-xl p-3"
            :class="{ 'bg-[#EEF0FC]': route.name === 'settings' }"
          >
            <base-icon class="w-full" icon-name="menusettings" />
          </router-link>
          <button class="menu-icon rounded-xl p-3" @click="closeSession">
            <base-icon class="w-full" icon-name="menulogout" />
          </button>
        </div>
      </div>
      <div
        class="test-new-menu-secondary-container flex h-full shrink-0 flex-grow flex-col bg-[#F7F8FF] px-4 py-6"
        :class="{ '!bg-[#F9E0E0]': isQA }"
      >
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 0 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(0)">
            Mis test
            <base-icon class="md:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <router-link to="/historical-tests" @click="toggleSecondaryMenu">Histórico test</router-link>
            <router-link to="/resume-last-test" @click="toggleSecondaryMenu">Retomar último test</router-link>
            <router-link :to="doTestLink.href" @click="toggleSecondaryMenu">Empezar test</router-link>
          </div>
        </div>
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 1 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(1)">
            Estadísticas
            <base-icon class="md:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <router-link :to="{ name: 'public-examination-user-statistics-no-slug' }" @click="toggleSecondaryMenu">
              Estadísticas generales
            </router-link>
            <router-link :to="{ name: 'public-examination-user-statistics-no-slug' }" @click="toggleSecondaryMenu">
              Estadísticas por tema
            </router-link>
            <router-link :to="{ name: 'public-examination-user-statistics-no-slug' }" @click="toggleSecondaryMenu">
              Estadísticas por ley
            </router-link>
          </div>
        </div>
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 2 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(2)">
            Campus
            <base-icon class="md:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <router-link to="/my-courses" @click="toggleSecondaryMenu">Mis cursos</router-link>
            <router-link to="/forums" @click="toggleSecondaryMenu">Foros</router-link>
            <router-link to="/calendar" @click="toggleSecondaryMenu">Calendario</router-link>
          </div>
        </div>
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 3 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(3)">
            Contacto
            <base-icon class="md:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <a href="mailto:soporte@ruthgalvan.es" @click="toggleSecondaryMenu">soporte@ruthgalvan.es</a>
            <a href="tel:+34676181193" @click="toggleSecondaryMenu">676 18 11 93 - Daniel</a>
            <a href="tel:+34674408620" @click="toggleSecondaryMenu">674 40 86 20 - Maribel</a>
          </div>
        </div>
        <div
          v-if="user != null && user.isStaff"
          class="secondary-menu mb-4 flex flex-col pb-4"
          :class="{ active: openSection === 4 }"
        >
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(4)">
            Administración
            <base-icon class="md:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <router-link :to="{ name: 'manage-users' }" @click="toggleSecondaryMenu">Usuarios</router-link>
            <router-link :to="{ name: 'staff-public-examination-statistics' }" @click="toggleSecondaryMenu">
              Estadísticas generales
            </router-link>
            <a :href="adminUrl" @click="toggleSecondaryMenu">Panel administración</a>
          </div>
        </div>
      </div>
    </div>
  </Disclosure>
</template>
