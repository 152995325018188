<script setup>
import { computed } from "vue";
import { toHuman } from "@/components/utils.js";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { PauseCircleIcon } from "@heroicons/vue/24/outline";
import MenuItemButton from "@/components/buttons/MenuItemButton.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import BaseIcon from "@/components/BaseIcon.vue";

const props = defineProps({
  record: {
    type: [Object, null],
    required: true,
  },
  onlyUncertain: {
    type: Boolean,
    default: false,
  },
  onlyHighlighted: {
    type: Boolean,
    default: false,
  },
  onlyNotAnswered: {
    type: Boolean,
    default: false,
  },
});

const elapsedTime = computed(() => {
  if (props.record != null) {
    return toHuman(props.record.elapsedTime);
  }
  return "--:--:--";
});
const emit = defineEmits(["save", "save-and-exit", "discard", "pause", "uncertain", "highlighted", "not-answered"]);

function save(closeFunction) {
  closeFunction();
  emit("save");
}
</script>

<template>
  <div class="flex flex-row-reverse items-center">
    <div class="ml-3 text-right">
      <Menu as="div" class="relative inline-block text-left align-middle">
        <div>
          <MenuButton
            class="inline-flex w-full justify-center rounded-lg bg-[#FEF4E5] bg-opacity-70 px-4 py-3 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            <base-icon icon-name="threedots" class="h-6 w-4 text-white hover:text-secondary-200" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-slate-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="px-1 py-1">
              <MenuItem>
                <menu-item-button>
                  <div class="flex flex-row">
                    <span class="mr-2">Solo dudosas</span>
                    <base-switch
                      id="id-switch-uncertain"
                      name="switch-uncertain"
                      class="absolute right-5"
                      :model-value="onlyUncertain"
                      @change="$emit('uncertain', $event)"
                    >
                      Solo dudosas
                    </base-switch>
                  </div>
                </menu-item-button>
              </MenuItem>
              <MenuItem>
                <menu-item-button>
                  <div class="flex flex-row">
                    <span class="mr-2">Solo marcadas</span>
                    <base-switch
                      id="id-switch-highlighted"
                      name="switch-highlighted"
                      class="absolute right-5"
                      :model-value="onlyHighlighted"
                      @change="$emit('highlighted', $event)"
                    >
                      Solo dudosas
                    </base-switch>
                  </div>
                </menu-item-button>
              </MenuItem>
              <MenuItem>
                <menu-item-button>
                  <div class="flex flex-row">
                    <span class="mr-2">Solo sin respuesta</span>
                    <base-switch
                      id="id-switch-not-answered"
                      name="switch-not-answered"
                      class="absolute right-5"
                      :model-value="onlyNotAnswered"
                      @change="$emit('not-answered', $event)"
                    >
                      Solo dudosas
                    </base-switch>
                  </div>
                </menu-item-button>
              </MenuItem>
            </div>
            <div class="px-1 py-1">
              <MenuItem>
                <menu-item-button @click.prevent="$emit('pause')">Pausar</menu-item-button>
              </MenuItem>
              <MenuItem v-slot="{ close }">
                <menu-item-button @click.prevent="save(close)">Guardar</menu-item-button>
              </MenuItem>
              <MenuItem>
                <menu-item-button @click.prevent="$emit('discard')">Descartar</menu-item-button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div class="mr-3 align-middle">
      <button-primary
        class="rounded-lg !px-6 !py-3 !text-base !font-medium text-white hover:bg-[#FEF4E5] hover:text-black"
        @click.prevent="$emit('save-and-exit')"
      >
        Terminar y corregir
      </button-primary>
    </div>
    <div class="mr-3 rounded-lg bg-[#FEF4E5] p-2 align-middle">
      <PauseCircleIcon class="block h-9 cursor-pointer text-secondary-600" @click.prevent="$emit('pause')" />
    </div>
    <div
      class="mr-3 flex min-w-[9rem] justify-center rounded-lg bg-[#FEF4E5] px-4 py-3 align-middle text-lg font-medium"
    >
      <span>{{ elapsedTime }}</span>
      <base-icon class="ml-2" icon-name="clock" />
    </div>
  </div>
</template>
