<script setup>
import { computed } from "vue";

const props = defineProps({
  steps: {
    type: Number,
    required: true,
  },
  currentStep: {
    type: Number,
    default: 1,
  },
});

const stepTexts = ["Oposición", "Configuración", "Contenido", "¡Empieza!"];

const range = computed(() => {
  return Array.from(Array(props.steps).keys());
});

const emit = defineEmits(["step-clicked"]);

function stepClicked(step) {
  if (step < props.currentStep) {
    emit("step-clicked", step);
  }
}
</script>

<template>
  <div class="flex justify-center sm:justify-start">
    <div v-for="i in range" :key="i" class="flex items-center">
      <div
        v-if="i > 0"
        class="h-[2px] w-20 transition-all sm:w-28"
        :class="currentStep >= i + 1 ? 'bg-secondary-800' : 'bg-secondary-210'"
      ></div>
      <div
        class="flex h-10 w-10 items-center rounded-full text-center text-xl text-white transition-all"
        :class="{
          'active bg-secondary-800': currentStep === i + 1,
          'bg-secondary-210': currentStep !== i + 1,
          'cursor-pointer': currentStep > i + 1,
        }"
        @click="stepClicked(i + 1)"
      >
        <span class="mx-auto">{{ stepTexts[i] }}</span>
      </div>
    </div>
  </div>
</template>
