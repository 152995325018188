<script setup>
import { ATTEMPT_CUSTOM } from "@/components/utils.js";
import { computed } from "vue";
import { attemptTypeIconSelector } from "@/components/icons.js";
import BaseIcon from "@/components/BaseIcon.vue";

const props = defineProps({
  type: {
    type: String,
    default: ATTEMPT_CUSTOM,
  },
  circleColor: {
    type: String,
    default: "green",
  },
  description: {
    type: String,
    required: true,
  },
  pillText: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["select-type"]);
const selectIcon = computed(() => {
  return attemptTypeIconSelector(props.type);
});

function selectType() {
  emit("select-type", props.type);
}
</script>

<template>
  <div>
    <a @click.prevent="selectType" class="cursor-pointer">
      <div class="">
        <div class="flex flex-col gap-4">
          <div class="mr-4 flex h-16 w-full shrink-0 items-center">
            <base-icon :icon-name="selectIcon" :title="selectIcon" :alt="selectIcon" />
            <div class="flex flex-grow items-center justify-end">
              <div class="opp-conf-item-pill">
                {{ pillText }}
              </div>
              <base-icon icon-name="arrowcircle" class="arrowcircle ml-3" />
            </div>
          </div>
          <div class="my-auto flex flex-col">
            <span class="mb-4 text-xl font-medium text-secondary-800"><slot>Test personalizado</slot></span>
            <span class="text-base font-normal text-secondary-800">
              {{ description }}
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
