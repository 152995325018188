<script setup>
import { onMounted, ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import MainRepository from "@/repositories/MainRepository.js";
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import { yesNoCapitalizedFormatter } from "@/components/utils.js";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import { EyeIcon } from "@heroicons/vue/24/outline/index.js";

const table = ref(null);

const { sendServerError } = useToast();

const initialPageSize = ref(10);
const initialOffset = ref(0);

const fields = [
  { label: "Notificaciones", key: "title" },
  { label: "Leido", key: "wasRead", formatter: yesNoCapitalizedFormatter },
  { label: "Acción", key: "actions" },
];

onMounted(async () => {
  try {
    table.value.setPageSizeAndOffset(parseInt(initialPageSize.value), parseInt(initialOffset.value));
    await refreshData();
  } catch (error) {
    sendServerError(error);
  }
});

async function refreshData() {
  try {
    await table.value.refreshData();
  } catch (e) {
    sendServerError(e, "NOTIFY-LIST");
  }
}

function storeOffset(data) {
  initialOffset.value = data.offset;
  initialPageSize.value = data.pageSize;
}
</script>

<template>
  <title-header-view>
    <div>
      <table-with-filters
        ref="table"
        title="Notificaciones de usuario"
        :fields="fields"
        :call="MainRepository.notificationList"
        :initial-offset="initialOffset"
        :initial-page-size="initialPageSize"
        :skip-initial-refresh="true"
        :show-loading-at-start="true"
        @offset-changed="storeOffset"
        class="notifications-container"
      >
        <template #cell(actions)="{ row }">
          <div class="flex flex-row">
            <router-link
              class="rounded p-1.5 hover:bg-primary-100"
              title="Detalle notificación"
              :to="{ name: 'notification-detail', params: { notificationId: row.id } }"
            >
              <EyeIcon class="block h-5 w-5" />
            </router-link>
          </div>
        </template>
      </table-with-filters>
    </div>
  </title-header-view>
</template>
