<script setup>
import { computed, ref } from "vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import ButtonLink from "@/components/buttons/ButtonLink.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import TheLoading from "@/components/TheLoading.vue";

const props = defineProps({
  contentLevels: {
    type: Array,
    required: true,
  },
  publicExamination: {
    type: Object,
    required: true,
  },
  titleSelector: {
    type: Function,
    required: true,
  },
  showDescription: {
    type: Boolean,
    default: false,
  },
  descriptionSelector: {
    type: Function,
    required: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const levelIndex = ref([]);
const selectedLevels = ref([]);

const levels = computed(() => {
  if (levelIndex.value.length === 0) {
    return props.contentLevels;
  }
  let levelsToShow = levelIndex.value[levelIndex.value.length - 1].children;
  return levelsToShow.toSorted((a, b) => a.order > b.order);
});

function removeChildren(children) {
  if (children != null) {
    for (let i = 0; i < children.length; i++) {
      removeChildren(children[i].children);
      removeContent(children[i]);
    }
  }
}

function nodeLevel(children, toSearch) {
  if (children != null) {
    for (let i = 0; i < children.length; i++) {
      if (toSearch.name === children[i].name) {
        removeChildren(children[i].children);
        return true;
      } else {
        if (nodeLevel(children[i].children, toSearch)) {
          removeContent(children[i]);
          return true;
        }
      }
    }
  }
  return false;
}

function addLevel(contentLevel) {
  const index = contentIndex(contentLevel);
  if (index > -1) {
    removeContentByIndex(index);
  } else {
    for (let i = 0; i < props.contentLevels.length; i++) {
      if (contentLevel.name === props.contentLevels[i].name) {
        removeChildren(props.contentLevels[i].children);
        break;
      } else {
        if (nodeLevel(props.contentLevels[i].children, contentLevel)) {
          if (removeContent(props.contentLevels[i])) break;
        }
      }
    }
    selectedLevels.value.push(contentLevel);
  }
}

function removeContent(contentLevel) {
  const index = contentIndex(contentLevel);
  if (index !== -1) {
    removeContentByIndex(index);
    return true;
  }
  return false;
}

function isSelected(contentLevel) {
  return contentIndex(contentLevel) !== -1;
}

function contentIndex(contentLevel) {
  return selectedLevels.value.map((e) => e.id).indexOf(contentLevel.id);
}

function removeContentByIndex(index) {
  selectedLevels.value.splice(index, 1);
}

function selectSubLevel(contentLevel) {
  levelIndex.value.push(contentLevel);
}

function childrenSelected(contentLevel) {
  let total = 0;
  if (isSelected(contentLevel)) {
    total = contentLevel.children.length;
  } else {
    for (let i = 0; i < contentLevel.children.length; i++) {
      if (isSelected(contentLevel.children[i])) {
        total += 1;
      }
    }
    // unselect children, select contentLevel
    if (total === contentLevel.children.length) {
      addLevel(contentLevel);
    }
  }
  return total;
}

function buttonLabel(contentLevel) {
  if (contentLevel.contentType === "Materias") return "Temas";
  if (contentLevel.contentType === "Leyes") return "Leyes";
  return "Epígrafes";
}

function showRoot() {
  levelIndex.value = [];
}

function showIndex(index) {
  if (index < 0) showRoot();
  for (let i = levelIndex.value.length - 1; i > index; i--) levelIndex.value.pop();
}

defineExpose({
  selectedLevels,
});
</script>

<template>
  <div class="">
    <div class="flex flex-col">
      <base-label label="Contenidos seleccionados" for-label="content-level-selector-selected" class="!text-xl" />
      <div id="content-level-selector-selected" class="mt-2 flex flex-wrap gap-2">
        <span v-if="selectedLevels.length === 0">Todos los contenidos</span>
        <div
          v-for="(content, index) in selectedLevels"
          :key="content.id"
          class="mb-2 mr-3 flex items-center justify-between gap-3 rounded-lg bg-background p-3 text-base font-light text-secondary-800"
        >
          <div class="">
            <p v-if="content.parentName != null">{{ content.parentName }} -</p>
            <p>{{ content.name }}</p>
          </div>
          <div class="w-4 shrink-0 cursor-pointer">
            <base-icon
              icon-name="delete"
              title="Borrar"
              alt="Borrar"
              class="w-full"
              @click="removeContentByIndex(index)"
            />
          </div>
        </div>
      </div>
      <base-label label="Selecciona contenido" for-label="content-level-selector-list" class="mt-4 !text-lg" />
      <div>
        <div
          id="selector-breadcrumb"
          class="my-2 flex w-[fit-content] flex-row items-center rounded-lg bg-background px-5 py-3"
        >
          <div>
            <base-icon
              icon-name="home"
              title="Nivel raíz"
              alt="Nivel raíz"
              @click.prevent="showRoot"
              class="cursor-pointer"
            />
          </div>
          <div v-for="(value, index) in levelIndex" :key="index">
            <span class="mx-2 text-base font-light text-secondary-800">&gt;</span>
            <a
              v-if="index !== levelIndex.length - 1"
              href="#"
              @click.prevent="showIndex(index)"
              class="text-base font-light text-secondary-800"
              >{{ value.name }}</a
            >
            <span v-else class="text-base font-light text-secondary-800">{{ value.name }}</span>
          </div>
        </div>
      </div>
      <ul id="content-level-selector-list" class="py-3">
        <li v-if="isLoading">
          <the-loading />
        </li>
        <li
          v-for="contentLevel in levels"
          :key="contentLevel.id"
          class="flex w-full cursor-pointer justify-between border-b border-b-secondary-100 py-2 hover:bg-secondary-50"
          @click.prevent="addLevel(contentLevel)"
        >
          <div class="flex pt-1 text-lg" :class="showDescription ? 'items-start' : 'items-center'">
            <div
              class="mr-4 flex h-5 w-5 flex-shrink-0 cursor-pointer items-center rounded"
              :class="[
                isSelected(contentLevel) ? 'bg-primary-800' : ' border border-2 border-secondary-350 bg-secondary-50',
                showDescription ? 'mt-1' : '',
              ]"
            >
              <base-icon
                v-show="isSelected(contentLevel)"
                icon-name="check"
                title="seleccionado"
                alt="seleccionado"
                class="mx-auto"
              />
            </div>
            <div class="flex flex-col">
              <div>{{ titleSelector(contentLevel) }}</div>
              <div v-if="showDescription" class="text-base font-light text-secondary-800">
                {{ descriptionSelector(contentLevel) }}
              </div>
            </div>
          </div>
          <div>
            <button-link
              v-if="contentLevel.children.length > 0"
              @click="selectSubLevel(contentLevel)"
              class="!py-1 !text-base"
              ><span> {{ childrenSelected(contentLevel) }}/{{ contentLevel.children.length }}</span>
              {{ buttonLabel(contentLevel) }}
            </button-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
