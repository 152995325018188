<script setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import MainRepository from "@/repositories/MainRepository.js";
import { usePagination } from "@/components/composables/ws-pagination.js";
import BaseCard from "@/components/BaseCard.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import ResultCanvas from "@/components/attempt-details/ResultCanvas.vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import RadioButton from "@/components/forms/RadioButton.vue";
import PublicExaminationStatisticsChart from "@/components/charts/PublicExaminationStatisticsChart.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import { useRoute, useRouter } from "vue-router";
import StatisticsSelectorFailedThemeList from "@/components/statistics/StatisticsSelectorFailedThemeList.vue";
import UserInputSelector from "@/components/forms/UserInputSelector.vue";
import { DateTime } from "luxon";
import { formatDate } from "@/components/utils.js";
import StatisticsSelectorFailedLawList from "@/components/statistics/StatisticsSelectorFailedLawList.vue";

const { sendServerError } = useToast();

const router = useRouter();
const route = useRoute();

const { items: oppositions, getList: getOppositions } = usePagination(MainRepository.publicExaminationList, 100, 0);

const opposition = ref(null);
const oppositionStatistics = ref(null);
let correctAnswerList = [];
let incorrectAnswerList = [];
let blankAnswerList = [];
const statisticsTime = ref("7");

const endDate = computed(() => {
  const today = DateTime.now();
  return formatDate(today);
});

const startDate = computed(() => {
  let firstDate = DateTime.now();

  if (statisticsTime.value === "7") {
    firstDate = firstDate.minus({ days: 7 });
  } else if (statisticsTime.value === "30") {
    firstDate = firstDate.minus({ days: 30 });
  } else if (statisticsTime.value === "365") {
    firstDate = firstDate.minus({ days: 365 });
  }

  return formatDate(firstDate);
});

const numberOfCorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.numberOfCorrectQuestion;
});
const percentageOfCorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.percentageCorrectQuestion;
});
const numberOfIncorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.numberOfIncorrectQuestion;
});
const percentageOfIncorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.percentageIncorrectQuestion;
});
const numberOfBlank = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.numberOfBlankQuestion;
});
const percentageOfBlank = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.percentageBlankQuestion;
});
const totalAttempts = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.totalAttempts;
});
const totalTime = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.totalTimeStringRepresentation;
});

const publicExaminationId = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return opposition.value.id;
});

const calculatePercentageCorrectDifference = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }

  const lastPercentageCorrect = oppositionStatistics.value.lastPercentageCorrectQuestion;
  const currentPercentageCorrect = oppositionStatistics.value.percentageCorrectQuestion;
  const diffCorrect = (currentPercentageCorrect - lastPercentageCorrect) * 100;

  if (diffCorrect >= 0) {
    return `Has aumentado un ${Math.round(Math.abs(diffCorrect))}% tus aciertos.`;
  } else {
    return `Has disminuido un ${Math.round(Math.abs(diffCorrect))}% tus aciertos.`;
  }
});

const calculatePercentageIncorrectDifference = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }

  const lastPercentageIncorrect = oppositionStatistics.value.lastPercentageIncorrectQuestion;
  const currentPercentageIncorrect = oppositionStatistics.value.percentageIncorrectQuestion;
  const diffIncorrect = (currentPercentageIncorrect - lastPercentageIncorrect) * 100;

  if (diffIncorrect >= 0) {
    return `Has aumentado un ${Math.round(Math.abs(diffIncorrect))}% tus fallos.`;
  } else {
    return `Has disminuido un ${Math.round(Math.abs(diffIncorrect))}% tus fallos.`;
  }
});

const calculatePercentageBlankDifference = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }

  const lastPercentageBlank = oppositionStatistics.value.lastPercentageBlankQuestion;
  const currentPercentageBlank = oppositionStatistics.value.percentageBlankQuestion;
  const diffBlank = (currentPercentageBlank - lastPercentageBlank) * 100;

  if (diffBlank >= 0) {
    return `Has aumentado un ${Math.round(Math.abs(diffBlank))}% tus respuestas en blanco.`;
  } else {
    return `Has disminuido un ${Math.round(Math.abs(diffBlank))}% tus respuestas en blanco.`;
  }
});

const filteredOppositions = computed(() => {
  return oppositions.value.filter((item) => item.userHasMembership === true);
});

const handleOppositionChange = async (selectedOpposition) => {
  try {
    await updateRoute(selectedOpposition.slug);
    await fetchOppositionStatistics();
  } catch (error) {
    sendServerError(error);
  }
};

const updateRoute = async (slug) => {
  await router.replace({ name: "public-examination-user-statistics", params: { slug: slug } });
};

const statisticsTimeOptions = [
  { label: "Últimos 365 días", value: "365" },
  { label: "Últimos 30 días", value: "30" },
  { label: "Últimos 7 días", value: "7" },
];

const isPercentage = ref(true);
const resultSelector = computed(() => [
  {
    label: "Porcentaje",
    value: true,
  },
  {
    label: "Valor absoluto",
    value: false,
  },
]);

const calculatePercentages = async () => {
  if (!isPercentage.value) {
    return;
  }

  const length = correctAnswerList.length;
  for (let i = 0; i < length; i++) {
    const correct = correctAnswerList[i].value;
    const incorrect = incorrectAnswerList[i].value;
    const blank = blankAnswerList[i].value;

    const total = correct + incorrect + blank;

    if (total === 0) {
      correctAnswerList[i].value = 0;
      incorrectAnswerList[i].value = 0;
      blankAnswerList[i].value = 0;
    } else {
      correctAnswerList[i].value = Math.round((correct / total) * 100);
      incorrectAnswerList[i].value = Math.round((incorrect / total) * 100);
      blankAnswerList[i].value = Math.round((blank / total) * 100);
    }
  }
};

function moveToThemes() {
  const slug = route.params.slug;
  router.push({
    name: "public-examination-themes-statistics",
    params: { slug },
    query: { statisticsTime: statisticsTime.value },
  });
}

function moveToLaws() {
  const slug = route.params.slug;
  router.push({
    name: "public-examination-laws-statistics",
    params: { slug },
    query: { statisticsTime: statisticsTime.value },
  });
}

watch(isPercentage, () => {
  refreshData();
});

onMounted(async () => {
  try {
    await fetchData(route.params.slug);
    await fetchOppositionStatistics();
  } catch (error) {
    sendServerError(error);
  }
});

const user = inject("user");
const userIdForStatistics = ref(null);

const changeUser = (newUserId) => {
  userIdForStatistics.value = newUserId;
  refreshData();
};

const fetchData = async (slug) => {
  try {
    await getOppositions({
      slug: slug,
    });
    if (slug) {
      opposition.value = filteredOppositions.value.find((item) => item.slug === slug);
    }
    if (!opposition.value && filteredOppositions.value.length > 0) {
      opposition.value = oppositions.value[0];
    }
    await router.replace({ name: "public-examination-user-statistics", params: { slug: opposition.value.slug } });
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
};

const refreshData = () => {
  fetchOppositionStatistics();
};

const fetchOppositionStatistics = async () => {
  const slug = route.params.slug;

  try {
    const response = await MainRepository.publicExaminationStatistics({
      slug,
      timeRange: statisticsTime.value,
      userId: userIdForStatistics.value,
    });
    oppositionStatistics.value = response.data;
    correctAnswerList = response.data.correctAnswerList;
    incorrectAnswerList = response.data.incorrectAnswerList;
    blankAnswerList = response.data.blankAnswerList;
    await calculatePercentages();
  } catch (error) {
    sendServerError(error, "FETCH-OPPOSITION-STATISTICS");
  }
};

const mostFailedContentLevels = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  const filteredList = oppositionStatistics.value.topFailThemeList.filter((item) => item.incorrectPercentage !== 0);
  return filteredList.slice(0, 5);
});
const hasMostFailedContentLevels = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return mostFailedContentLevels.value.length > 0;
});

const mostFailedLaw = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  const filteredList = oppositionStatistics.value.topFailLawList.filter((item) => item.incorrectPercentage !== 0);
  return filteredList.slice(0, 5);
});
const hasMostFailedLaws = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return mostFailedLaw.value.length > 0;
});
</script>

<template>
  <title-header-view>
    <div>
      <div class="flex justify-between">
        <h2 class="text-3xl font-semibold text-secondary-800">Estadísticas</h2>
      </div>

      <base-card class="custom-shadow !mx-0 mt-16 w-full rounded-3xl border-0 p-8 drop-shadow-none">
        <div class="w-full">
          <base-label label="Oposición" for-label="select-opposition" />
          <base-complex-select
            v-model="opposition"
            id="select-opposition"
            name="select-opposition"
            :value-option="(item) => item.id"
            :label-option="(item) => item.name"
            :options="filteredOppositions"
            @update:model-value="handleOppositionChange"
          >
          </base-complex-select>
        </div>
      </base-card>

      <base-card
        v-if="user !== null && user.isSuperuser"
        class="custom-shadow !mx-0 mt-8 w-full rounded-3xl border-0 bg-admin p-8 drop-shadow-none"
      >
        <div class="w-full">
          <base-label label="Cambiar usuario" for-label="user-id" />
          <user-input-selector id="user-id" @user-id="changeUser" />
        </div>
      </base-card>

      <base-card class="custom-shadow !mx-0 mt-8 w-full rounded-3xl border-0 p-8 drop-shadow-none">
        <div class="flex">
          <div v-if="opposition" class="mb-5 mt-3 flex flex-col">
            <p class="text-xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
            <p class="text-md font-light text-secondary-800">{{ opposition.name }}</p>
          </div>
          <div class="ml-auto flex items-center text-center">
            <p class="ml-2 text-xs font-medium text-secondary-400">Del {{ startDate }} al {{ endDate }}</p>
            <div class="ml-3">
              <base-select
                v-model="statisticsTime"
                id="select-statistics-time"
                name="select-statistics-time"
                :options="statisticsTimeOptions"
                @change="refreshData"
              />
            </div>
          </div>
        </div>
        <fade-transition>
          <div class="mb-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <div class="flex w-full items-center rounded-lg bg-background p-4">
              <result-canvas :value="numberOfCorrect" :percentage="percentageOfCorrect" />
              <div class="ml-4 flex flex-col">
                <div class="text-lg font-medium">Correctas</div>
                <div class="text-base font-light">{{ numberOfCorrect }} respuestas</div>
                <div class="text-sm font-light">{{ calculatePercentageCorrectDifference }}</div>
              </div>
            </div>
            <div class="flex w-full items-center rounded-lg bg-background p-4">
              <result-canvas
                :value="numberOfIncorrect"
                :percentage="percentageOfIncorrect"
                percentage-color="#EB4949"
              />
              <div class="ml-4 flex flex-col">
                <div class="text-lg font-medium">Incorrectas</div>
                <div class="text-base font-light">{{ numberOfIncorrect }} respuestas</div>
                <div class="text-sm font-light">{{ calculatePercentageIncorrectDifference }}</div>
              </div>
            </div>
            <div class="flex w-full items-center rounded-lg bg-background p-4">
              <result-canvas :value="numberOfBlank" :percentage="percentageOfBlank" percentage-color="#97d5e8" />
              <div class="ml-4 flex flex-col">
                <div class="text-lg font-medium">En blanco</div>
                <div class="text-base font-light">{{ numberOfBlank }} respuestas</div>
                <div class="text-sm font-light">{{ calculatePercentageBlankDifference }}</div>
              </div>
            </div>
          </div>
        </fade-transition>
        <div class="mb-5 mt-10 flex">
          <div class="ml-auto flex items-center text-center">
            <radio-button v-model="isPercentage" :options="resultSelector" id="radio-law-content-level" />
          </div>
        </div>
        <public-examination-statistics-chart
          :blank-answers="blankAnswerList"
          :incorrect-answers="incorrectAnswerList"
          :correct-answers="correctAnswerList"
          :is-percentage="isPercentage"
          :show-day-in-date-label="statisticsTime !== '365'"
        >
        </public-examination-statistics-chart>
        <div class="flex flex-row">
          <div class="m-4 flex w-full items-center rounded-lg bg-background px-5 py-4">
            <div class="flex">
              <div class="mr-4 flex h-16 w-16 shrink-0 items-center">
                <div class="p-0">
                  <base-icon icon-name="tnumber"></base-icon>
                </div>
              </div>
              <div class="my-auto flex flex-col">
                <p class="text-lg font-semibold text-secondary-800">{{ totalAttempts }}</p>
                <p class="text-base font-light text-secondary-800">Test realizados</p>
              </div>
            </div>
          </div>
          <div class="m-4 flex w-full flex-row items-center rounded-lg bg-background px-5 py-4">
            <div class="flex">
              <div class="mr-4 flex h-16 w-16 shrink-0 items-center">
                <div class="p-0">
                  <base-icon icon-name="ttimer"></base-icon>
                </div>
              </div>
              <div class="my-auto flex flex-col">
                <p class="text-lg font-semibold text-secondary-800">{{ totalTime }}</p>
                <p class="text-base font-light text-secondary-800">Horas y minutos totales</p>
              </div>
            </div>
          </div>
        </div>
      </base-card>
      <base-card class="custom-shadow !mx-0 mt-8 w-full rounded-3xl border-0 p-8 drop-shadow-none">
        <div class="flex">
          <div v-if="opposition" class="mb-5 mt-3 flex flex-col">
            <p class="text-xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
            <p class="text-md font-light text-secondary-800">{{ opposition.name }}</p>
          </div>
        </div>
        <div>
          <div class="mb-5 mt-3 flex flex-col">
            <p class="text-xl font-medium text-secondary-800">Preguntas falladas por tema</p>
            <p class="text-md font-light text-secondary-800">
              Resultados de los test personalizados por ley, tema o parte general/procesal
            </p>
          </div>
          <div class="flex flex-row">
            <p v-if="!hasMostFailedContentLevels" class="text-md p-16 text-center font-light text-secondary-800">
              No se encontraron test personalizados con preguntas falladas.
            </p>
            <div v-else class="flex w-1/2 flex-grow flex-col">
              <statistics-selector-failed-theme-list
                :content-levels="mostFailedContentLevels"
                :public-examination-id="publicExaminationId"
              />
            </div>
            <div class="flex w-1/2 items-end justify-center">
              <img src="@/assets/attempt-statistics.svg" alt="Estadisticas por temas" />
            </div>
          </div>
          <div class="mt-5 flex w-1/2 flex-grow flex-col">
            <a @click.prevent="moveToThemes" class="cursor-pointer">
              <div class="flex items-center rounded-lg bg-background px-5 py-4 hover:bg-background-hover">
                <div class="flex w-full">
                  <div class="flex w-1/2 flex-col">
                    <p class="text-lg font-semibold text-secondary-800">Ver todos los temas</p>
                  </div>
                  <div class="ml-auto">
                    <base-icon icon-name="content_level_statistics" title="ver temas"></base-icon>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </base-card>
      <base-card class="custom-shadow !mx-0 mt-8 w-full rounded-3xl border-0 p-8 drop-shadow-none">
        <div class="flex">
          <div v-if="opposition" class="mb-5 mt-3 flex flex-col">
            <p class="text-xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
            <p class="text-md font-light text-secondary-800">{{ opposition.name }}</p>
          </div>
        </div>
        <div>
          <div class="mb-5 mt-3 flex flex-col">
            <p class="text-xl font-medium text-secondary-800">Preguntas falladas por ley</p>
            <p class="text-md font-light text-secondary-800">
              Resultados de los test personalizados por ley, tema o parte general/procesal
            </p>
          </div>
          <div class="flex flex-row">
            <p v-if="!hasMostFailedLaws" class="text-md p-16 text-center font-light text-secondary-800">
              No se encontraron test personalizados con preguntas falladas.
            </p>
            <div v-else class="flex w-1/2 flex-grow flex-col">
              <statistics-selector-failed-law-list :laws="mostFailedLaw" />
            </div>
          </div>
          <div class="mt-5 flex w-1/2 flex-grow flex-col">
            <a @click.prevent="moveToLaws" class="cursor-pointer">
              <div class="flex items-center rounded-lg bg-background px-5 py-4 hover:bg-background-hover">
                <div class="flex w-full">
                  <div class="flex w-1/2 flex-col">
                    <p class="text-lg font-semibold text-secondary-800">Ver todas las leyes</p>
                  </div>
                  <div class="ml-auto">
                    <base-icon icon-name="content_level_statistics" title="ver leyes"></base-icon>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </base-card>
    </div>
  </title-header-view>
</template>
